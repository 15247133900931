.container {
  display: block;
  composes: primaryBackgroundColor from global;
  composes: importantMessageTopBar from global;
}

.inner {
  color: #fff;
  padding: 10px 0;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.inner div:first-child {
  flex: 1;
}

.inner div:last-child {
  border: 1px solid #fff;
  padding: 0 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
