.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: #fafafa;
  border-bottom: 1px solid #eee;
}

.secure {
  color: #1bb700;
  flex: 1;
  display: flex;
  align-items: center;
}

.secureText {
  margin-left: 10px;
}

.logo,
.logo img {
  height: 22px;
}
