.container {
  padding: 20px;
}

.cancel {
  display: block;
  padding: 20px;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: bold;
}

.cancel:hover {
  color: #111;
}
