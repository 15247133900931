.container {
  background-color: var(--primary-color);
  top: 0;
  z-index: 300;
  padding: 5px;
}

.inner {
  padding: 3px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.description {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
}
