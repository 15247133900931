.container {
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  padding: 0 5px;
  margin-bottom: 10px;
  background: #fff;
  transition: all 0.5s ease;
}

.inputs {
  display: flex;
}

.ready {
  composes: container;
  composes: primaryBorderColor from global;
}

.inputContainer {
  flex: 1;
  text-align: center;
}

.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  color: #ddd;
  padding: 0 10px;
}

.input {
  width: 26px;
  height: 30px;
  outline: none;
  margin: 6px;
  padding: 0px 0;
  text-align: center;
  font-size: 20px;
  font-family: inherit;
  font-weight: bold;
  border: none;
  border-bottom: solid 1px #ddd;
  transition: all 0.5s ease;
  color: transparent;
  text-shadow: 0 0 0 #111;
}

.input:focus {
  border-color: #0069ff;
  outline: none;
}

.input:active {
  border-color: #ddd;
}

.input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ddd;
}
