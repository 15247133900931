.container {
  composes: mainFooter from global;
  padding: 80px 0;
}

.justoBrandContainer {
  composes: container;
  padding-bottom: 0;
}

.logo {
  width: 150px;
  margin-bottom: 10px;
}

.container h3 {
  margin-top: 30px;
}

.link {
  display: block;
  margin-top: 10px;
}

.btnLink {
  display: block;
  margin-top: 10px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
}

.row {
  display: flex;
}

.rowCenter {
  display: flex;
  align-items: center;
}

.complaintLogo {
  height: 100px;
}

.noMargin {
  margin: 0;
  font-size: 1em;
  line-height: 1em;
}

.marginBook {
  display: flex;
  align-items: center;
  margin-right: 0.5em;
}

.bookLink {
  display: block;
  margin-top: 1em;
}

.center {
  display: flex;
  justify-content: center;
}

.poweredByJusto {
  display: inline-flex;
  align-items: center;
  padding: 5px;
  margin: 35px 0;
  opacity: 0.6;
}

.poweredText {
  font-size: 12px;
  margin-right: 4px;
}

@media (max-width: 767px) {
  .logoContainer {
    text-align: center;
  }
}
