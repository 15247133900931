.container {
  text-align: center;
  padding: 20px;
}

.header {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
}

.text {
  white-space: pre-line;
}
