.container {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  color: #111;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  overflow: auto;
  text-align: left;
}

.modalInner {
  composes: shadow from global;
  border-radius: 5px;
  background: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  max-width: 400px;
  max-height: 100%;
  text-align: center;
}

@media (max-width: 767px) {
  .container {
    padding: 5%;
  }
}
